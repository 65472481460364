import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { green } from "@material-ui/core/colors";
import { ArrowLeft, ArrowRight, Check } from "@material-ui/icons";
import OperationalPanel from "./OperationalPanel";
import TreeGridNode from "./TreeGridNode";
import Searchbox from "Jarvis/JarvisComponents/Searchbox";
import Button from "../Button";
import Checkbox from "../Checkbox";

let totalRowHeight = 0;
const rowHeights = [];

export default function TreeGridBody({
  columns,
  currentRow,
  customOperationItems,
  displayMode,
  editOnDoubleClick,
  expandableColumnIndex,
  expandingNode,
  fetchData,
  fontSize,
  footer,
  hasOperationalPanel,
  hasRowSelection,
  height,
  idFieldName,
  isDirectionRTL,
  isLeafSelectableOnly,
  isLoading,
  isLoadingExpand,
  isLoadingPagination,
  isLoadingText,
  isTriple,
  levelOffset,
  nodeCanBeSelectedIf,
  noItemsText,
  onAddClick,
  onDeleteClick,
  onEditClick,
  onRefreshClick,
  onRowDoubleClick,
  onRowFiltering,
  onRowSelect,
  onRowSelectClick,
  onRowSelectionClick,
  onToggleExpand,
  pageIndices,
  PE,
  setCurrentRow,
  setExpandingNode,
  setSelectedNode,
  showBadge,
  showDragHandler,
  showOperationalPanel,
  showSearchRow,
  stateObject,
  trStyle,
  whereClause,
  width,
  wrapText,
}) {
  const bodyRef = useRef(null);
  const { control, setValue } = useForm();
  const { language } = useSelector((state) => state.settings);
  const [currentNode, setCurrentNode] = useState(null);
  const [operator, setOperator] = useState("");
  const [pagingNode, setPagingNode] = useState(null);
  //const [draggedNode, setDraggedNode] = useState(null);
  //const [isDragging, setIsDragging] = useState(false);
  const data = stateObject.dataToDisplay;
  const tbodyStyle = {
    height: `${height}px`,
    width: `${width}px`,
    display: "block",
    overflowY: "auto",
    overflowX: "hidden",
    outline: "none",
  };

  let isCurrentRowFound = false;

  useEffect(() => {
    if (data?.length > 0 && displayMode !== "tree") {
      if (wrapText) {
        for (let i = 0; i < data.length; i++) {
          rowHeights[i] = parseInt(
            window.getComputedStyle(bodyRef.current?.rows[i]).height
          );
        }
      } else {
        rowHeights[0] = parseInt(
          window.getComputedStyle(bodyRef.current?.rows[0]).height
        );
      }
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const getStyle = (column) => {
    let tdStyle = {
      width: `${column.width}px`,
      position: "relative",
    };
    if (wrapText) tdStyle.wordBreak = "break-word";
    else tdStyle.whiteSpace = "nowrap";
    if (column.type === "date") tdStyle.direction = "ltr";
    if (column.cellDir !== undefined) tdStyle.direction = column.cellDir;
    return tdStyle;
  };

  const getRowStyle = (row) => {
    let currentRowStyle;
    if (
      currentRow &&
      !isCurrentRowFound &&
      idFieldName &&
      row[idFieldName] === currentRow[idFieldName]
    ) {
      isCurrentRowFound = true;
      currentRowStyle = { border: "2px solid #217346" };
    }
    if (row.Style)
      if (_.isObject(row.Style))
        return _.merge(trStyle, row.Style, currentRowStyle);
      else return _.merge(trStyle, JSON.parse(row.Style), currentRowStyle);

    return _.merge(trStyle, currentRowStyle);
  };

  const handleRowClick = (row) => {
    if (row.pageIndex) return;

    const index = data.indexOf(row);
    totalRowHeight = 0;
    for (let i = 0; i <= index; i++) {
      totalRowHeight += wrapText ? rowHeights[i] : rowHeights[0];
    }

    setCurrentRow(row);
  };

  const handleRowDoubleClick = (row) => {
    if (row.pageIndex) return;

    if (editOnDoubleClick) {
      onEditClick(row);
    } else if (onRowDoubleClick) onRowDoubleClick(row);
  };

  const getCellAlign = (column) => {
    if (
      (column.type === "checkbox" || column.type === "switch") &&
      !column.cellAlign
    )
      return "text-center";
    if (isDirectionRTL) {
      if (column.cellAlign === undefined) return "text-left";
      else {
        switch (column.cellAlign) {
          case "left":
            return "text-right";
          case "right":
            return "text-left";
          default:
            return "text-center";
        }
      }
    } else {
      if (column.cellAlign === undefined) return "text-left";
      else {
        switch (column.cellAlign) {
          case "left":
            return "text-left";
          case "right":
            return "text-right";
          default:
            return "text-center";
        }
      }
    }
  };

  const getFontClass = (column) => {
    if (
      column.type === "number" ||
      column.type === "numberFormat" ||
      column.type === "date" ||
      column.type === "code"
    )
      return `Jarvis-Font-default Jarvis-Font-Size-${fontSize + 1}rem`;

    return `${_.get(
      language,
      "JarvisFontClass"
    )} Jarvis-Font-Size-${fontSize}rem`;
  };

  const isSelectable = (row, column) => {
    if (row.pageIndex) return false;
    if (columns.indexOf(column) !== expandableColumnIndex) return false;
    if (isLeafSelectableOnly && row.hasChildren) return false;
    if (nodeCanBeSelectedIf) return nodeCanBeSelectedIf(row);
    return true;
  };

  const getCheckbox = (row, column) => {
    if (
      isSelectable(row, column) &&
      (isLeafSelectableOnly === false || !row.hasChildren)
    )
      return (
        <Checkbox
          className={isDirectionRTL ? "p-0 mr-1" : "p-0 ml-1"}
          value={row.selected}
          isTriple={isTriple}
          size="small"
          onChange={() => handleRowSelect(row)}
        />
      );
  };

  const getIndent = (row, column) => {
    if (columns.indexOf(column) !== expandableColumnIndex) return null;
    let indent = ((row.Level !== undefined ? row.Level : 1) - 1) * levelOffset;

    if (!row.hasChildren) indent += 20;

    return (
      <span
        className="treegrid-indent"
        style={{
          width: `${indent}px`,
        }}
      ></span>
    );
  };

  const isClickable = (row, column) => {
    return columns.indexOf(column) === expandableColumnIndex && row.hasChildren;
  };

  const hasBadge = (row, column) => {
    if (
      showBadge &&
      row.hasChildren &&
      columns.indexOf(column) === expandableColumnIndex
    )
      return true;
    return false;
  };

  //const handleDrag = (node) => {
  //setIsDragging(true);
  //setDraggedNode(node);
  //};

  const renderCell = (row, column) => {
    if (_.get(row, "pageIndex")) {
      if (columns.indexOf(column) !== expandableColumnIndex) return null;

      return (
        <Button
          label={`${row.pageIndex} ${_.get(PE, "From") ?? "/"} ${
            row.pageCount
          }`}
          showLoading={isLoadingPagination && row.nodeId === pagingNode.nodeId}
          onClick={() => handleClickNextPage(row)}
          endIcon={isDirectionRTL ? <ArrowLeft /> : <ArrowRight />}
        />
      );
    }

    if (column.type === "checkbox" && !column.content) {
      if (_.get(row, column.path))
        return (
          <Check
            style={{
              color: green[500],
            }}
            fontSize="small"
          />
        );
      else return null;
    }

    return (
      <TreeGridNode
        column={column}
        displayMode={displayMode}
        expandingNode={expandingNode}
        hasRowSelection={hasRowSelection}
        highlightedText={
          _.get(
            whereClause.find((where) => where.FieldName === `${column.path}`),
            "Value"
          ) || ""
        }
        idFieldName={idFieldName}
        isClickable={isClickable(row, column)}
        isDirectionRTL={isDirectionRTL}
        isLoadingExpand={isLoadingExpand}
        //onDragStart={(node) => handleDrag(node)}
        //onDragStop={() => setIsDragging(false)}
        onClick={() => handleExpandChange(row)}
        row={row}
        showBadge={hasBadge(row, column)}
        stateObject={stateObject}
      />
    );
  };

  const getFooterValue = (column) => {
    return (
      _.get(
        footer.find((col) => col.path === column.path),
        "value"
      ) || ""
    );
  };

  const renderSearchbox = () => {
    if (showSearchRow && columns)
      return (
        <tr>
          {columns.map((column, index) => (
            <td
              style={getStyle(column)}
              className="align-middle text-center"
              key={`search${index}`}
            >
              <Searchbox
                control={control}
                name={column.path}
                onSearch={(searchText) => handleSearch(searchText, column)}
                operator={operator}
                setOperator={setOperator}
                setValue={setValue}
                type={column.type !== "checkbox" ? column.type : undefined}
                PE={PE}
                clearSearch
                hasOperators
                fullWidth
                showSearchIcon
              />
            </td>
          ))}
        </tr>
      );
  };

  const getOperationalPanel = (row, column) => {
    if (
      columns.indexOf(column) === expandableColumnIndex &&
      row === currentNode
    )
      return (
        <OperationalPanel
          currentRow={row}
          customOperationItems={customOperationItems}
          hasOperationalPanel={hasOperationalPanel}
          onRefreshClick={() => onRefreshClick(row)}
          onAddClick={() => onAddClick(true, row)}
          onDeleteClick={() => onDeleteClick(row)}
          onEditClick={() => onEditClick(row)}
          PE={PE}
          setSelectedNode={setSelectedNode}
          showSearch={showSearchRow}
        />
      );
  };

  const handleExpandChange = (node) => {
    setExpandingNode(node);
    onToggleExpand(node);
  };

  const handleClickNextPage = (row) => {
    const index = pageIndices.findIndex((item) => item.nodeId === row.nodeId);
    if (index !== -1) {
      const pageIndex = _.get(pageIndices[index], "pageIndex") ?? 1;
      pageIndices[index].pageIndex = pageIndex + 1;
    } else
      pageIndices.push({
        nodeId: row.nodeId,
        pageIndex: 2,
      });

    setPagingNode(row);

    if (row.Level === 1) fetchData();
    else
      onToggleExpand(
        data.find((item) => item[idFieldName] === row.nodeId),
        true
      );
  };

  const handleRowSelect = (selectedRow) => {
    onRowSelectionClick(selectedRow);
    if (onRowSelect) onRowSelect(selectedRow);
  };

  const handleSearch = (searchText, column) => {
    if (searchText) {
      let FieldName = column.searchFieldName ?? column.path;
      let DataType = "STRING";
      let Value = searchText;
      let Operator = "";
      switch (column.type) {
        case "checkbox":
        case "switch":
          DataType = "BIT";
          Operator = "=";
          break;
        case "number":
        case "numberFormat":
          DataType = "NUMBER";
          if (operator === "") Operator = "=";
          else Operator = operator;
          break;
        default:
          switch (operator) {
            case "equals":
              Operator = "=";
              break;
            case "notContain":
              Operator = "NOT LIKE";
              break;
            case "contains":
              Operator = "LIKE";
              break;
            case "startsWith":
              Operator = "StartsWith";
              break;
            case "endsWith":
              Operator = "EndsWith";
              break;
            case "null":
              Value = "IsNull";
              Operator = "";
              break;
            case "isNotNull":
              Value = "IsNotNull";
              Operator = "";
              break;
            default:
              Operator = "LIKE";
              break;
          }
          break;
      }
      onRowFiltering({ FieldName, DataType, Operator, Value });
    } else
      onRowFiltering({
        FieldName: column.searchFieldName ?? column.path,
        Value: null,
      });
  };

  const handleKeyDown = (e) => {
    if (currentRow) {
      let index = 0;
      const len = data.length;

      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          index = data.indexOf(currentRow);
          if (index === len - 1) {
            totalRowHeight = e.target.scrollHeight;
            e.target.scrollTop = totalRowHeight;
            setCurrentRow(data[index]);
          } else {
            let nextRowHeight = 0;
            nextRowHeight = wrapText ? rowHeights[index + 1] : rowHeights[0];
            totalRowHeight += nextRowHeight;

            if (totalRowHeight - e.target.scrollTop > e.target.clientHeight) {
              e.target.scrollTop += nextRowHeight + 1;
            }
            setCurrentRow(data[index + 1]);
          }
          break;
        case "ArrowUp":
          e.preventDefault();
          index = data.indexOf(currentRow);
          if (index === 0) {
            totalRowHeight = wrapText ? rowHeights[index] : rowHeights[0];
            e.target.scrollTop = 0;
            setCurrentRow(data[index]);
          } else {
            totalRowHeight -= rowHeights[index];
            let preRowHeight = 0;
            preRowHeight = wrapText ? rowHeights[index - 1] : rowHeights[0];

            if (totalRowHeight - e.target.scrollTop < preRowHeight) {
              e.target.scrollTop -= preRowHeight;
            }
            setCurrentRow(data[index - 1]);
          }
          break;
        case "ArrowLeft":
          if (currentRow.hasChildren) {
            if (isDirectionRTL) {
              if (!currentRow.expanded) handleExpandChange(currentRow);
            } else if (currentRow.expanded) handleExpandChange(currentRow);
          } else {
            if (!isDirectionRTL) {
              handleExpandChange(currentRow.parent);
              setCurrentRow(currentRow.parent);
            }
          }
          break;
        case "ArrowRight":
          if (currentRow.hasChildren) {
            if (isDirectionRTL) {
              if (currentRow.expanded) handleExpandChange(currentRow);
            } else if (!currentRow.expanded) handleExpandChange(currentRow);
          } else {
            if (isDirectionRTL) {
              handleExpandChange(currentRow.parent);
              setCurrentRow(currentRow.parent);
            }
          }
          break;
        case "Home":
          totalRowHeight = rowHeights[0];
          setCurrentRow(data[0]);
          break;
        case "End":
          totalRowHeight = e.target.scrollHeight;
          setCurrentRow(data[len - 1]);
          break;
        case "Delete":
          onDeleteClick(currentRow);
          break;
        case "Enter":
          e.preventDefault();
          handleRowDoubleClick(currentRow);
          break;
        case "Insert":
          onAddClick(true);
          break;
        case "+":
          e.preventDefault();
          onAddClick(true, currentRow);
          break;
        case " ":
          e.preventDefault();
          if (nodeCanBeSelectedIf && nodeCanBeSelectedIf(currentRow)) {
            if (!hasRowSelection)
              onRowSelectClick((stateObject) => ({
                ...stateObject,
                hasSelectColumn: true,
              }));
            onRowSelectionClick(currentRow);
          }
          break;
        default:
          break;
      }
    }
  };

  if (isLoading || _.isEmpty(data))
    return (
      <tbody className="table-borderless" style={tbodyStyle}>
        {renderSearchbox()}
        <tr>
          <td
            className={clsx(
              language.JarvisFontClass,
              `Jarvis-Font-Size-${fontSize}rem`,
              "jarvisBody"
            )}
            key={"noitems"}
          >
            {isLoading ? isLoadingText : showSearchRow ? noItemsText : ""}
          </td>
        </tr>
      </tbody>
    );

  return (
    <tbody
      ref={bodyRef}
      style={tbodyStyle}
      tabIndex="1"
      onKeyDown={handleKeyDown}
    >
      {renderSearchbox()}
      {data?.map((row, index) => {
        return (
          <tr
            key={row[idFieldName] ?? `row-${index}`}
            style={getRowStyle(row)}
            className={
              hasRowSelection && row.selected && displayMode !== "tree"
                ? "row-selected"
                : ""
            }
            onClick={
              displayMode !== "tree" ? () => handleRowClick(row) : undefined
            }
            onDoubleClick={() => handleRowDoubleClick(row)}
            onMouseEnter={() => setCurrentNode(row)}
            onMouseLeave={() => setCurrentNode(null)}
          >
            {columns?.map((column, index) => (
              <td
                style={getStyle(column)}
                className={clsx(
                  "align-middle",
                  getCellAlign(column),
                  getFontClass(column),
                  "jarvisBody"
                )}
                key={index}
              >
                {getIndent(row, column)}
                {hasRowSelection && getCheckbox(row, column)}
                {renderCell(row, column)}
                {showOperationalPanel ? getOperationalPanel(row, column) : null}
              </td>
            ))}
          </tr>
        );
      })}
      {footer && (
        <tr key="footer">
          {columns?.map((column, index) => (
            <td
              style={getStyle(column)}
              className={clsx(
                "align-middle",
                getCellAlign(column),
                language.JarvisFontClass,
                `Jarvis-Font-Size-${fontSize}rem`,
                "jarvisBody"
              )}
              key={`footer${index}`}
            >
              {getFooterValue(column)}
            </td>
          ))}
        </tr>
      )}
    </tbody>
  );
}
