/*------------
Version: 1.8
------------*/
import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClearIcon from "@material-ui/icons/Clear";
import TextField from "Jarvis/JarvisComponents/TextField";
import { Menu, MenuItem } from "@material-ui/core";
import Typography from "../Typography";
import "./index.scss";

const operators = [
  { key: "=", value: 61 },
  { key: "<=", value: 0x2264 },
  { key: ">=", value: 0x2265 },
  { key: "<", value: 60 },
  { key: ">", value: 62 },
  { key: "<>", value: 0x2260 },
];

export default function Searchbox({
  autoFocus,
  className,
  clearSearch,
  control,
  fontSize,
  fullWidth,
  hasOperators,
  label,
  name,
  onSearch,
  operator,
  PE,
  placeholder,
  setOperator,
  setValue,
  showSearchIcon,
  textAlign,
  type,
  value,
  variant,
  width,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [textFieldValue, setTextFieldValue] = useState(value ?? "");

  const length = textFieldValue.trim().length;

  const handleClear = () => {
    if (clearSearch) {
      onSearch(null);
    }
    if (length > 0) {
      setValue(name, "");
      setTextFieldValue("");
    }
    document.getElementsByName(name)[0].focus();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && onSearch) {
      e.preventDefault();
      if (length > 0) {
        onSearch(textFieldValue.trim());
      }
    }

    if (e.key === "Escape") {
      handleClear();
    }
  };

  const handleChange = (e) => setTextFieldValue(e.target.value);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (value) => {
    if (setOperator) setOperator(value);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <TextField
        label={label}
        className={className}
        control={control}
        name={name}
        style={{ width: width }}
        placeholder={placeholder}
        fontSize={fontSize}
        fullWidth={fullWidth}
        autoFocus={autoFocus}
        type={type}
        textAlign={textAlign}
        variant={variant}
        value={value}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <Fragment>
              {length > 0 && setValue ? (
                <ClearIcon
                  className="searchBox"
                  fontSize="small"
                  color="inherit"
                  onClick={handleClear}
                />
              ) : undefined}
            </Fragment>
          ),
          startAdornment: (
            <Fragment>
              {showSearchIcon && length === 0 && (
                <SearchIcon color="disabled" />
              )}
              {hasOperators && (
                <ExpandMoreIcon
                  className="searchBox"
                  fontSize="small"
                  onClick={handleMenuClick}
                />
              )}
            </Fragment>
          ),
        }}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {type === "number" || type === "numberFormat" ? (
          operators.map((op) => (
            <MenuItem
              className={op.key === operator ? "selected" : ""}
              key={op.key}
              selected={op.key === operator}
              onClick={() => handleMenuItemClick(op.key)}
              dense
            >
              {String.fromCharCode(0x202d) + String.fromCharCode(op.value)}
            </MenuItem>
          ))
        ) : (
          <div>
            <MenuItem
              className={operator === "contains" ? "selected" : ""}
              selected={operator === "contains"}
              onClick={() => handleMenuItemClick("contains")}
            >
              <Typography
                label={_.get(PE, "Contains") || "Contains"}
                fontSize={8}
              />
            </MenuItem>
            <MenuItem
              className={operator === "notContain" ? "selected" : ""}
              selected={operator === "notContain"}
              onClick={() => handleMenuItemClick("notContain")}
            >
              <Typography
                label={_.get(PE, "NotContain") || "Do Not Contain"}
                fontSize={8}
              />
            </MenuItem>
            <MenuItem
              className={operator === "startsWith" ? "selected" : ""}
              selected={operator === "startsWith"}
              onClick={() => handleMenuItemClick("startsWith")}
            >
              <Typography
                label={_.get(PE, "StartsWith") || "Starts With"}
                fontSize={8}
              />
            </MenuItem>
            <MenuItem
              className={operator === "endsWith" ? "selected" : ""}
              selected={operator === "endsWith"}
              onClick={() => handleMenuItemClick("endsWith")}
            >
              <Typography
                label={_.get(PE, "EndsWith") || "Ends With"}
                fontSize={8}
              />
            </MenuItem>
            <MenuItem
              className={operator === "equals" ? "selected" : ""}
              selected={operator === "equals"}
              onClick={() => handleMenuItemClick("equals")}
            >
              <Typography
                label={_.get(PE, "Equals") || "Equals"}
                fontSize={8}
              />
            </MenuItem>
            <MenuItem
              className={operator === "null" ? "selected" : ""}
              selected={operator === "null"}
              onClick={() => handleMenuItemClick("null")}
            >
              <Typography label={_.get(PE, "Blank") || "Blank"} fontSize={8} />
            </MenuItem>
            <MenuItem
              className={operator === "isNotNull" ? "selected" : ""}
              selected={operator === "isNotNull"}
              onClick={() => handleMenuItemClick("isNotNull")}
            >
              <Typography
                label={_.get(PE, "NotBlank") || "Not Blank"}
                fontSize={8}
              />
            </MenuItem>
          </div>
        )}
      </Menu>
    </div>
  );
}

Searchbox.propTypes = {
  className: PropTypes.string,
  clearSearch: PropTypes.bool,
  fontSize: PropTypes.number,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  searchBoxText: PropTypes.string,
  showSearchIcon: PropTypes.bool,
  width: PropTypes.string,
};

Searchbox.defaultValue = {
  clearSearch: true,
  fontSize: 8,
  showSearchIcon: true,
  type: "text",
};
