import React, { Fragment } from "react";
import _ from "lodash";
import Badge from "@material-ui/core/Badge";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExpandProgress from "./ExpandProgress";
import {
  digitGrouping,
  isNullOrWhiteSpace,
} from "Jarvis/JarvisServices/JarvisCommonService";
//import DraggableNode from "./DraggableNode";

export default function TreeGridNode({
  column,
  expandingNode,
  highlightedText,
  idFieldName,
  isClickable,
  isDirectionRTL,
  isLoadingExpand,
  onDragStart,
  onDragStop,
  onClick,
  row,
  showBadge,
  stateObject,
}) {
  const getExpandIcon = () => {
    return (
      <span className="treegrid-expander">
        {isLoadingExpand &&
          expandingNode &&
          row[idFieldName] === expandingNode[idFieldName] && (
            <ExpandProgress showLoading={isLoadingExpand} />
          )}
        {row.expanded ? (
          <ExpandMoreIcon fontSize="small" />
        ) : isDirectionRTL ? (
          <ChevronLeftIcon fontSize="small" />
        ) : (
          <ChevronRightIcon fontSize="small" />
        )}
      </span>
    );
  };

  const getHighlightedText = (text, highlight) => {
    if (isNullOrWhiteSpace(text)) return "";

    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <Fragment>
        {parts.map((part, i) => (
          <span
            key={`highlight${i}`}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? { backgroundColor: "#9abbea" }
                : {}
            }
          >
            {part}
          </span>
        ))}
      </Fragment>
    );
  };

  const renderCellContent = () => {
    if (highlightedText.length > 0)
      return getHighlightedText(
        _.get(row, column.path)?.toString(),
        highlightedText
      );

    if (column.content) return column.content(row, stateObject);

    if (column.type === "numberFormat")
      return !isNullOrWhiteSpace(_.get(row, column.path))
        ? digitGrouping(_.get(row, column.path))
        : "";

    return _.get(row, column.path) || "";
  };

  if (isClickable)
    return (
      <Fragment>
        <span
          className="cursor-pointer"
          onClick={!isLoadingExpand ? onClick : undefined}
        >
          {getExpandIcon()}
          {renderCellContent()}
        </span>
        {showBadge && (
          <Badge
            style={{ whiteSpace: "nowrap" }}
            className="px-2"
            badgeContent={row.ChildrenCount}
            color="primary"
            max={999}
          />
        )}
      </Fragment>
    );

  return renderCellContent();

  // if (isClickable)
  //   return (
  //     <DraggableNode
  //       onDragStart={() => onDragStart(row)}
  //       onDragStop={onDragStop}
  //     >
  //       {renderNode()}
  //     </DraggableNode>
  //   );
}
