import React, { useState, useContext } from "react";
import _ from "lodash";
import Avatar from "@material-ui/core/Avatar";
import { useSelector, useDispatch } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { ExitToApp, Lock, VpnKey } from "@material-ui/icons";
import { userSignOut } from "Jarvis/JarvisActions/Auth";
import GlobalContext from "Jarvis/JarvisContexts/GlobalContext";
import { jarvisComponentMustBeExcluded } from "Jarvis/JarvisServices/JarvisBaseSettingsService";
import { ifNullOrWhiteSpace } from "Jarvis/JarvisServices/JarvisCommonService";
import Typography from "Jarvis/JarvisComponents/Typography";
import { UserLogout } from "Jarvis/JarvisSystems/Sec/Services/UserService";
import TwoFactorAuth from "Jarvis/JarvisSystems/Sec/Components/TwoFactorAuth";
import Dialog from "Jarvis/JarvisComponents/Dialog";
import ChangePassword from "./ChangePassword";
import IconButton from "Jarvis/JarvisComponents/IconButton";

export default function UserMenu(props) {
  if (jarvisComponentMustBeExcluded("UserInfo")) return null;
  return <Index {...props} />;
}

const Index = ({ onShowUserSettings }) => {
  const { PE } = useContext(GlobalContext);
  const dispacth = useDispatch();
  const authUser = useSelector((state) => state.auth.authUser);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [stateObj, setStateObj] = useState({
    dialogTitle: "",
    menuSelected: "",
  });

  const langData = _.get(PE, "SecUser");
  let userDescription = ifNullOrWhiteSpace(
    _.get(authUser, "Fullname"),
    _.get(authUser, "UserDescription")
  );
  if (!userDescription) userDescription = _.get(authUser, "username");
  const handleClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  // const handleUserSettingsClick = () => {
  //   onShowUserSettings((stateObject) => ({
  //     ...stateObject,
  //     showUserSettings: true,
  //   }));
  //   handleRequestClose();
  // };

  const handleUserChangePasswordClick = () => {
    setStateObj((state) => ({
      ...state,
      dialogTitle: _.get(langData, "SecUserChangePassword"),
      menuSelected: "changePassword",
    }));
    setShowDialog(true);
    handleRequestClose();
  };

  const handleSecUserTwoFactorAuthClick = () => {
    setStateObj((state) => ({
      ...state,
      dialogTitle: _.get(langData, "TwoFactorAuth"),
      menuSelected: "twoFactorAuth",
    }));
    setShowDialog(true);
    handleRequestClose();
  };

  const RenderComponent = () => {
    switch (stateObj.menuSelected) {
      case "changePassword":
        return <ChangePassword onClose={() => setShowDialog(false)} />;
      case "twoFactorAuth":
        return <TwoFactorAuth onClose={() => setShowDialog(false)} />;
      default:
        break;
    }
  };

  const handleLogout = async () => {
    setIsLoading(true);
    const { ErrorHandling } = await UserLogout();
    setIsLoading(false);
    if (_.get(ErrorHandling, "IsSuccessful")) {
      handleRequestClose();
      dispacth(userSignOut());
      window.location.reload();
    }
  };

  return (
    <div className="user-profile d-flex flex-row align-items-center">
      {showDialog && (
        <Dialog
          width="50%"
          disableBackdropClick
          open={showDialog}
          title={stateObj.dialogTitle}
          onClose={() => setShowDialog(false)}
        >
          <RenderComponent />
        </Dialog>
      )}
      <Avatar
        alt={userDescription}
        src={_.get(authUser, "UserImageBase64")}
        className="user-avatar"
      />
      <div
        style={{ cursor: "pointer" }}
        className="user-detail"
        onClick={handleClick}
      >
        <Typography label={userDescription} />
        <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
      </div>
      <Menu
        className="user-info"
        anchorEl={anchorEl}
        open={open}
        onClose={handleRequestClose}
        PaperProps={{
          style: {
            minWidth: 120,
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        {/* <MenuItem onClick={handleRequestClose}>
          <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
          <Typography label={_.get(langData, "SecUser_UserProfile")} />
        </MenuItem>
        <MenuItem onClick={handleUserSettingsClick}>
          <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
          <Typography label={_.get(langData, "SecUser_UserSetting")} />
        </MenuItem> */}
        {!authUser.UserMustLoginWithDomainController && (
          <MenuItem onClick={handleUserChangePasswordClick}>
            <VpnKey className="mr-2" fontSize="small" />
            <Typography label={_.get(langData, "SecUserChangePassword")} />
          </MenuItem>
        )}
        <MenuItem onClick={handleSecUserTwoFactorAuthClick}>
          <Lock className="mr-2" fontSize="small" />
          <Typography label={_.get(langData, "TwoFactorAuth")} />
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <IconButton
            className="px-0 mr-2"
            icon={<ExitToApp fontSize="small" />}
            showLoading={isLoading}
          />
          <Typography label={_.get(PE, "PubPublicElems.Logout")} />
        </MenuItem>
      </Menu>
    </div>
  );
};
