/*------------
Version: 1.2
------------*/
import React, { useContext } from "react";
import _ from "lodash";
import GlobalContext from "Jarvis/JarvisContexts/GlobalContext";
import TextField from "../TextField";
import Checkbox from "../Checkbox";
import DatePicker from "../DatePicker";

export default function DynamicColumn({
  columns,
  control,
  errors,
  rules,
  setValue,
  singleRow,
}) {
  const { PE } = useContext(GlobalContext);

  if (_.isEmpty(columns)) return null;

  const langData = _.pick(PE.PubPublicElems, ["Required"]);

  const getCommonProps = (column) => {
    let commonProps = {
      control,
      errors,
      name: column.ColumnName,
      value: column.value,
      label: column.LangName || column.ColumnName,
    };
    if (column.DataType === "Int" || column.DataType === "Real") {
      if (column.DataType === "Int") commonProps.decimalScale = 0;
      else commonProps.allowNegative = true;
      commonProps.type = "number";
      commonProps.textAlign = "left";
    }
    return commonProps;
  };

  const getRules = (column) => {
    const result = rules ? [...rules] : [];
    if (column.IsNull === false)
      result.push({ required: _.get(langData, "Required") || "required" });
    if (_.isEmpty(result)) return undefined;

    return result;
  };

  const renderComponent = (column) => {
    if (column.DataType === "Bit")
      return <Checkbox {...getCommonProps(column)} />;

    if (column.DataType === "Date")
      return (
        <DatePicker
          {...getCommonProps(column)}
          inputVariant="standard"
          required={column.IsNull === false}
          rules={getRules(column)}
          setValue={setValue}
          fullWidth
        />
      );

    return (
      <TextField
        {...getCommonProps(column)}
        required={column.IsNull === false}
        rules={getRules(column)}
        fullWidth
      />
    );
  };

  return (
    <div>
      <div className="row">
        {columns.map((column) => {
          return (
            <div
              key={column.ColumnName}
              className={singleRow ? "col-12 mt-2" : "col-md-6 col-sm-12 mt-2"}
            >
              {renderComponent(column)}
            </div>
          );
        })}
      </div>
    </div>
  );
}
