import React from "react";
import IconButton from "../IconButton";
import { Add, Delete, Edit, Refresh } from "@material-ui/icons";

export default function MainOperations({
  currentRow,
  addTooltip,
  deleteTooltip,
  editTooltip,
  refreshTooltip,
  hasAddButton,
  hasEditButton,
  hasDeleteButton,
  onAddClick,
  onDeleteClick,
  onEditClick,
  onRefreshClick,
  showSearch,
}) {
  return (
    <React.Fragment>
      {hasAddButton && (
        <IconButton
          size="small"
          component="span"
          edge={false}
          icon={<Add className="operational-panel" fontSize="small" />}
          tooltip={addTooltip}
          onClick={onAddClick}
        />
      )}
      {hasEditButton && (
        <IconButton
          size="small"
          component="span"
          edge={false}
          icon={<Edit className="operational-panel" fontSize="small" />}
          tooltip={editTooltip}
          onClick={onEditClick}
        />
      )}
      {hasDeleteButton && (
        <IconButton
          size="small"
          component="span"
          edge={false}
          icon={<Delete className="operational-panel" fontSize="small" />}
          tooltip={deleteTooltip}
          onClick={onDeleteClick}
        />
      )}
      {!showSearch && currentRow.hasChildren && (
        <IconButton
          size="small"
          component="span"
          edge={false}
          icon={<Refresh className="operational-panel" fontSize="small" />}
          tooltip={refreshTooltip}
          onClick={onRefreshClick}
        />
      )}
    </React.Fragment>
  );
}
